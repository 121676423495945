import axios, { type AxiosResponse } from 'axios';
import { format } from 'date-fns';

const address = 'https://ip.chaletlejar.com:304';

export const sendRewind = async (data: string): Promise<void> => {
  try {
    await axios.post(`${address}/rewind`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendSave = async (): Promise<void> => {
  try {
    await axios.post(`${address}/save`);
  } catch (error) {
    console.error(error);
  }
};

export const sendSfx = async (data: string): Promise<void> => {
  try {
    await axios.post(`${address}/sfx`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendMessage = async (data: string): Promise<void> => {
  try {
    await axios.post(`${address}/message`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendTeleport = async (data: {
  username: string;
  coordinate: string;
  locationName: string;
}): Promise<void> => {
  try {
    await axios.post(`${address}/teleport`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendAddTicking = async (data: {
  coordinate: string;
  radius: string;
  locationName: string;
}): Promise<void> => {
  try {
    await axios.post(`${address}/addticking`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendRemoveTicking = async (data: {
  locationName: string;
}): Promise<void> => {
  try {
    await axios.post(`${address}/removeticking`, { data });
  } catch (error) {
    console.error(error);
  }
};

export const sendClearTicking = async (): Promise<void> => {
  try {
    await axios.post(`${address}/clearticking`);
  } catch (error) {
    console.error(error);
  }
};

export const sendResetTicking = async (): Promise<void> => {
  try {
    await axios.post(`${address}/resetticking`);
  } catch (error) {
    console.error(error);
  }
};

export const sendResetServer = async (): Promise<void> => {
  try {
    await axios.post(`${address}/resetserver`);
  } catch (error) {
    console.error(error);
  }
};

export const sendResetClient = async (): Promise<void> => {
  // restartClientHelper();
};

export const sendUpgradeServer = async (): Promise<void> => {
  try {
    await axios.post(`${address}/upgradeserver`);
  } catch (error) {
    console.error(error);
  }
};

export const getPlayerStatus = async (): Promise<string[]> => {
  try {
    const players = await axios.get(`${address}/playerstatus`);
    const data = players.data as Record<string, string[]>;
    return data.users;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getServerVersion = async (): Promise<string> => {
  try {
    const version = await axios.get(`${address}/serverversion`);
    const data = version.data as Record<string, string>;
    return data.version ?? '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

const formatData = (
  fetch: AxiosResponse,
): { raw: string; localDate: string }[] => {
  const log = fetch.data as string;
  if (log) {
    const lines = log.split('\n');
    const formattedLines = [];
    for (const raw of lines) {
      if (raw) {
        const formatted = raw.replace('_', ' ').replace('-', '/');
        const localDate = format(new Date(formatted), 'Pp');
        const dateData = { raw, localDate };
        formattedLines.push(dateData);
      }
    }

    return formattedLines;
  }
  return [];
};

export const fetchSaveData = async (
  resultsCap: number,
): Promise<
  {
    raw: string;
    localDate: string;
  }[]
> => {
  const fetch = await axios.get(`${address}/savelog/${resultsCap}`);
  return formatData(fetch);
};

export const fetchAutosaveData = async (
  resultsCap: number,
): Promise<
  {
    raw: string;
    localDate: string;
  }[]
> => {
  const fetch = await axios.get(`${address}/autosavelog/${resultsCap}`);
  return formatData(fetch);
};

export const fetchRewindData = async (
  resultsCap: number,
): Promise<
  {
    raw: string;
    localDate: string;
  }[]
> => {
  const fetch = await axios.get(`${address}/rewindlog/${resultsCap}`);
  return formatData(fetch);
};
