import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../Footer';
import { getServerVersion } from '../helpers/apiHelper';

const Home = ({
  activePlayerCount,
}: {
  activePlayerCount: number;
}): React.JSX.Element => {
  const navigate = useNavigate();

  const [serverVersion, setServerVersion] = useState('');

  const getVersion = async (): Promise<void> => {
    const version = await getServerVersion();
    setServerVersion(version);
  };

  useEffect(() => {
    const getStatus = async (): Promise<void> => {
      await getVersion();
    };
    void getStatus();
  }, []);

  return (
    <div className="home">
      <button
        className="home-button"
        onClick={() => {
          navigate('/rewind');
        }}
      >
        <span>REWIND</span>
      </button>
      <button
        className="home-button"
        onClick={() => {
          navigate('/teleport');
        }}
      >
        <span>TELEPORT</span>
      </button>
      <button
        className="home-button"
        onClick={() => {
          navigate('/sfx');
        }}
      >
        <span>SFX</span>
      </button>
      <button
        className="home-button"
        onClick={() => {
          navigate('/message');
        }}
      >
        <span>MESSAGE</span>
      </button>
      <button
        className="home-button"
        onClick={() => {
          navigate('/save');
        }}
      >
        <span>SAVE</span>
      </button>
      <button
        className="home-button"
        onClick={() => {
          navigate('/settings');
        }}
      >
        <span>SETTINGS</span>
      </button>
      <Footer
        footerValue={`Bedrock Version: ${serverVersion}`}
        activePlayerCount={activePlayerCount}
      />
    </div>
  );
};

export default Home;
